import * as React from "react";

import Breadcrumps from "@components/Breadcrumbs";
import BigCommunique from "@components/BigCommunique";
import Seo from "@components/Seo";

import { StaticImage } from "gatsby-plugin-image";

function NotFoundPage() {
  const breadcrumbs_data = [{ name: "404" }];
  return (
    <>
      <Seo
        title="404 - strony nie znaleziono | Dream Factory"
        description="404 - Nie znaleziono strony"
      />
      <div className="message-sent-page">
        <div className="container">
          <Breadcrumps data={breadcrumbs_data} />
          <main>
            <BigCommunique description="Strona o podanym adresie nie została znaleziona na naszej stronie.">
              <StaticImage src="../images/404.svg" alt="404" />
            </BigCommunique>
          </main>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
